ul.tag-list {
	margin: 0;
	padding: 0;
	overflow: visible
}

ul.tag-list li {
	margin: 0;
	padding: 0;
	font-size: 18px;
	color: #262626;
	font-weight: 300;
	font-style: italic;
	margin-right: 5px
}

ul.tag-list li span {
	color: #e9485d;
	display: inline-block;
	font-weight: 300;
	font-style: italic
}

ul.tag-list li span::after {
	content: ',';
	color: #262626
}

ul.tag-list li:last-child span::after {
	display: none
}