.block__usp-list-horizontal {
	border-top: 2px solid #EDEDED;
	border-bottom: 2px solid #EDEDED;
	img {
		max-width: rem-calc(50);
	}

	.item {
		@include transition(all);
		padding: rem-calc(30 20);
		font-size: rem-calc(17);
		line-height: rem-calc(24);

		h3 {
			color: $secondary-color;
			font-weight: 700;
			font-size: rem-calc(18);
			margin-bottom: rem-calc(3);
		}
		p {
			color: $secondary-color;
		}
		&:hover {
			transform: scale(1.05);
			box-shadow: 5px 5px 25px rgba(0,0,0,0.16);
		}
	}

	.link {
		font-weight: 600;
		position: relative;
		padding-right: 10px;
		display: inline-block;
		&:after {
			position: absolute;
			height: 7.5px;
			width: 4px;
			right: 0;
			top: -1px;
			content: url("../images/pink-arrow.svg");
		}
	}
	.cell {
		&.text-white {
			* {
				color: $white;
			}
			.link {
				&:after {
					content: url("../images/white-arrow.svg");
				}
			}
		}
	}
}