.rie{
    &__topbar{
        height: 60px !important;
        @include breakpoint(large){
            height: 105px !important;
        }
        .topbar__wrapper{
            height: 60px !important;
            @include breakpoint(large){
                height: 105px !important;
            }
            z-index: 5;
            border-bottom:0 !important;
        }
        .page__content{
            @include breakpoint(medium down) {
                padding-top: 60px;
            }
        }
    }
    &__menu{
        a{
            font-size: 13px;
            font-weight: 300;
            position: relative;
            padding: 30px 8px 32px;
        }
        .dropdown-pane.content{
            min-width:365px;
            a{
                display: block;
                width: 100%;
                left: 0;
                position: relative;
                bottom:0;
                background:$primary-color;
                padding:15px !important;
                text-align: center;
                color:white;
                &::after{
                    display: none;
                }
            }
            .file-download {
                background:transparent;
                border: 2px solid $primary-color;
                color: $primary-color;
                width: 50%;
                float: left;
                padding: 3px !important;
                img {
                    width: 30px;
                }
                &.left {
                    border-right: 0px;
                }
                &:hover {
                    color: $primary-color;
                }
            }
            .dropdown-content{
                padding:0 10px 10px 0;
                text-align:left;
                h5{
                    font-weight: 700;
                }
                *{
                    color:#262626;
                }
            }
            &::before{
                left: inherit;
                right: 10px;
            }
            &::after{
                left: inherit;
                right: 9px;
            }

        }
    }
}
