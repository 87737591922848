ul.pagination {
    list-style-type: none;
    padding-bottom: 20px;

    li {
        float: left;

        a, span {
            padding: 3px;
        }
    }
}
