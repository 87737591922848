.page-banner {
		background-color: #F0F0F0;
		padding: 50px 0;
		background-size: cover;
		background-position: center center;
	&__text {
		padding: 30px 20px;
		border-left: 6px solid $primary-color;
		overflow: hidden;
		background: $white;
		color: #262626;
		h1 {
			font-weight: 700;
		}
	}

	.banner .banner-content .banner-text h2 {
		margin-bottom: 0
	}
}