.search-results {
	&__item {
		display: block;
		padding: 30px 0;
		border-top: 2px solid #E6E6E6;
		.readmore {
			margin-right: 50px;
			transition: all 150ms ease-in-out;
			i {
				&:after {
					display: inline-block;
					content: '';
					width: 22px;
					height: 22px;
					vertical-align: top;
					background-image: url(../images/secondary-arrow--right.svg);
					margin-left: 10px;
				}
			}
		}
		&:hover {
			.readmore {
				margin-right: 0;
				i {
					&:after {
						background-image: url(../images/primary-arrow--right.svg);
					}
				}
			}
			.search-results__title {
				color: $primary-color;
			}
		}
	}

	&__title {
		color: $secondary-color;
		transition: all 150ms ease-in-out;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 8px;
	}
	&__text {
		color: $secondary-color;
	}
	&__url {
		margin-bottom: 0;
	}
	&__badge {
		font-size: 11px;
		font-weight: 600;
		text-transform: uppercase;
		display: block;
		color: $primary-color;
	}

}
.searchbar {
	background: $primary-color;
	color: $white;
	position: fixed;
	z-index: -999;
	left: 15px;
	right: 15px;
	height: 0;
	opacity: 0;
	overflow: hidden;
	padding: 10px;
	@include transition(all);
	top: 40%;
	transform: translateY(-30%);
	//width: 90%;
	margin: 0 auto;

	@include breakpoint(medium) {
		top: 30%;
		left: 50%;
		right: auto;
		transform: translate(-50%, -50%);
		width: 450px;
	}

	form {
		opacity: 0;
		position: relative;
		//left: -40px;
		@include transition(all, 300ms);
		transition-delay: 300ms;
	}

	.searchicon {
		float: left;
		width: 40px;
		height: 54px;
		background-color: rgba(255,255,255,0.2);
		background-size: 20px 20px;
		background-repeat: no-repeat;
		background-position: center center;
		display: inline-block;
		border: 2px solid #fff;
		border-right: 0;
		top: 0;
	}
	.searchfield {
		float: left;
		border: 2px solid #fff;
		border-left: 0;
		background-color: rgba(255,255,255,0.2);
		display: inline-block;
		width: calc(100% - 40px);
		color: white;
		height: 54px;
		margin: 0;
		font-size: 21px;
		font-weight: 400;
		font-style: italic;
		&::placeholder {
			color: white;
			opacity: 1
		}
	}
	&-backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: #f6f6f6;
		opacity: 0;
		z-index: -999;
		visibility: hidden;
		//-ms-touch-action: pan-y;
		//touch-action: none;
		@include transition(all);

	}
	&-title {
		text-align: center;
		font-size: rem-calc(18);
		font-weight: 600;
		//padding-bottom: 10px;
		position: relative;
		p {
			font-size: 14px;
		}
		.close {
			position: absolute;
			right: -5px;
			top: -5px;
			background-image: url(../images/icon-cross.svg);
			width: 30px;
			height: 30px;
			cursor: pointer;
			background-size: 15px 15px;
			background-repeat: no-repeat;
			background-position: center center;
			-webkit-tap-highlight-color: transparent;
			@include transition(all);
			&:hover {
				transform: scale(1.25);
			}
		}
	}
	&.is-visible {
		visibility: visible;
		height: 138px;
		opacity: 1;
		z-index: 1000;
		transition-delay: 250ms;
		box-shadow: 0 10px 10px rgba(0,0,0,0.15);
		form {
			opacity: 1;
			left: 0;
		}
		+ .searchbar-backdrop {
			visibility: visible;
			opacity: 0.8;
			z-index: 999;
			+ .page__container {
				filter: blur(3px);
			}
		}
	}
}