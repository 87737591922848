@import "rie-menu";

.rie__login{
    position:relative;
    width:100%;
    min-height: 100vh;
    padding: 100px 0;
}

ul.riee-list {
    counter-reset: section;
    //margin-top: 25px;
    margin-left: 0;
    margin-right: 0;
    list-style: none;
    li {
        counter-increment: section;
        padding-left: 65px;
        padding-bottom: 22px;
        position: relative;
        border-bottom: 1px solid grey;
        padding-top: 16px;
        cursor: pointer;
        &::before {
            content: url(../images/check-empty.svg);
            position: absolute;
            top: 23px;
            left: 10px;
            width: 35px;
            height: 35px;
        }
        &.active::before {
            content: url(../images/check.svg);
        }
        a {
            display: inline-block;
            &:hover {
                text-decoration: underline;
            }
        }
        h4 {
            margin-bottom: 0;
            font-weight: 700;
            color: #262626;
            line-height: 2.4rem;
            &::before {
                content: counter(section) ". ";
            }
        }
        p {
            margin-top: -5px;
            color: #262626;
        }
    }
}

.rie-container {
    z-index: 40;
    a.return-link {
        position: relative;
        padding-left: 20px;
        display: inline-block;
        color: #262626;
        font-style: italic;
        font-size: 18px;
        font-weight: 300;

        &:before {
            content: url(../images/ico-arrow-grey.svg);
            position: absolute;
            top: -5px;
            left: 0;
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg)
        }

        &:hover {
            color: #e9485d !important;

            &:before {
                content: url(../images/ico-arrow-pink.svg)
            }
        }

    }
    .line {
        border-top: 1px solid #bdbdbd;
    }
    .question-column {
        box-shadow: 10px 0 5px -2px rgba(136,136,136,0.2);
        position: relative;
        padding-top: 45px;
        padding-right: 0;
        z-index: 2;
        input[type=text],
        textarea {
            border: 3px solid #898989;
            &:focus {
                border-color: #e9485d;
            }
        }
        textarea {
            height: 150px;
            text-transform: inherit;
            resize: none;

        }
        hr {
            margin: 30px 0;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        h2 {
            font-size: 25px;
            line-height: 31px;
            margin-bottom: 15px;
        }
        h5 {
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 5px;

        }
        .question {
            margin-left: -.9375rem;
            margin-right: -.9375rem;
            padding: 32px 32px 32px 0;

            .question-degree {
                .pencil {
                    top: 2px;
                    position: relative;
                }

                .question-degree-adjustment {
                    display: none;
                }

                &.edit {
                    .pencil {
                        display: none;
                    }
                    .question-degree-adjustment {
                        position: relative;
                        height: 1rem;
                        display: inline-block;
                        width: 50px;
                        select {
                            margin-bottom: 0;
                            position: absolute;
                            left: 0;
                            bottom: -12px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

aside.vraag-en-antwoord-aside {
    z-index: 0;
    margin-left: -.9375rem;
    margin-right: -.9375rem;
    .sticky-container {
        z-index: 0;
    }
}
aside.vraag-en-antwoord-aside ul.side-nav {
    padding: 0;
    overflow: visible;
    position: relative;
    list-style: none;
    margin: 0;
}

aside.vraag-en-antwoord-aside ul.side-nav h4 {
    margin: 0;
    font-weight: 700;
    color: #262626
}

aside.vraag-en-antwoord-aside ul.side-nav li {
    position: relative;
    z-index: 5;
    font-size: .875rem;
    font-weight: 300;
    margin: 0;
    &:after {
        content: url(../images/ico-arrow-grey.svg);
        border: none;
        width: 9px;
        height: 28px;
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
aside.vraag-en-antwoord-aside ul.side-nav li:first-child {
    &:after {
        display: none;
    }
}
aside.vraag-en-antwoord-aside ul.side-nav li:first-child h4 {
    padding: 26px 45px 27px 32px
}

aside.vraag-en-antwoord-aside ul.side-nav li a {
    position: relative;
    display: block;
    margin: 0;
    line-height: inherit;
    padding: 1rem 2.8125rem 1rem 2rem;
}

aside.vraag-en-antwoord-aside ul.side-nav li a::before {
    content: '';
    top: 0;
    position: absolute;
    background: #404040;
    left: 0;
    height: 1px;
    width: 5000px;
    z-index: 10
}

aside.vraag-en-antwoord-aside ul.side-nav li a:hover,aside.vraag-en-antwoord-aside ul.side-nav li a.active {
    background: #262626
}

aside.vraag-en-antwoord-aside ul.side-nav li a:hover::after,aside.vraag-en-antwoord-aside ul.side-nav li a.active::after {
    width: 5000px;
    height: 100%;
    background: #262626;
    content: '';
    position: absolute;
    left: 100%;
    top: 0
}

aside.vraag-en-antwoord-aside ul.side-social li h4 {
    color: #262626
}

aside.vraag-en-antwoord-aside ul.side-social li a {
    background: white;
    color: #262626;
    font-weight: 700
}

aside.vraag-en-antwoord-aside ul.side-social li a:hover,aside.vraag-en-antwoord-aside ul.side-social li a.active {
    color: white;
    padding-left: 40px
}

aside.vraag-en-antwoord-aside ul.side-social li a::before {
    background: #c2c2c2
}

aside.vraag-en-antwoord-aside .primary-content-right {
    padding: 10px 45px 12px 32px
}

aside.vraag-en-antwoord-aside .primary-content-right h2 {
    font-size: 34px;
    line-height: 35px;
    margin-bottom: 0
}

aside.vraag-en-antwoord-aside .primary-content-right::before {
    display: none
}

.riee-remark {
    margin-top: 12px;
}