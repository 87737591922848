.sidebar-filter {
	h3 {
		font-weight: 700;
		@include breakpoint(medium) {
			margin-bottom: 33px
		}
	}

	ul {
		//margin: 0
	}

	li {
		display: block;
		width: 100%;
		margin-bottom: 10px
	}

	label {
		color: #B7B7B7;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		font-family: "Exo 2", sans-serif
	}

	a {
		font-size: 16px;
		font-weight: 500;
		color: #525252;
		position: relative;
		padding-left: 33px;
		&:before, &:after {
			transition: all 150ms ease-in-out;
			width: 22px;
			height: 22px;
			top: 0px;
			left: 0;
			position: absolute;
			display: inline-block;
			content: ''
		}

		&:before {
			border: 2px solid #E6E6E6;
			margin-right: 10px
		}

		&:after {
			background-image: url("../images/checkmark--pink.svg");
			background-repeat: no-repeat;
			background-size: 13px;
			background-position: center;
			opacity: 0
		}

		&:hover:before {
			border-color: $primary-color
		}

		&.active:before {
			border-color: $primary-color
		}

		&.active:after {
			opacity: 1
		}
	}
}

.overview__list {
	margin: 0;
	padding-top: 10px;
	border-bottom: 2px solid #E6E6E6;
	&-item {
		list-style-type: none;
		border-top: 2px solid #E6E6E6;
		padding: 20px 0 23px;
		width: 100%;
	}

	&-breadcrumbs {
		font-size: 11px;
		font-weight: 600;
		text-transform: uppercase;
		display: block;
		color: $primary-color;
	}

	h3 {
		transition: all 150ms ease-in-out;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 8px
	}

	p {
		color: #252525
	}

	a {
		display: block;
		position: relative;
		color: #262626;
		padding-right: 45px;
		@include breakpoint(medium) {
			padding-right: 80px;
		}
	}

	@media screen and (min-width: 64.063em) {
		a {
			padding-right: 180px
		}
	}

	a:after {
		transition: all 150ms ease-in-out;
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(../images/secondary-arrow--right.svg);
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
		right: 20px;
		@include breakpoint(medium) {
			right: 50px;
		}
	}


	a:hover h3 {
		color: $primary-color;
	}

	a:hover:after {
		background-image: url(../images/primary-arrow--right.svg);
		right: 10px
	}
}


.gezondwerken__detail-header {
	padding: 30px 0;
	a {
		&.back {
			font-size: .875rem;
			font-weight: 700;
			color: white;
			text-transform: uppercase;
			position: relative;
			padding-left: 30px;
			margin-top: -20px;
		}
		&.category {
			font-weight: 700;
		}
		&.back::before {
			transition: all 150ms ease-in-out;
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: -.0625rem;
			background-image: url(../images/white-arrow--left.svg);
			background-repeat: no-repeat;
			width: 20px;
			height: 20px;
		}
	}
	h1 {
		line-height: 1;
		font-size: 2.1875rem;
		@include breakpoint(large){
			font-size: 3.125rem;
		}
	}
	.category {
		font-size: .6875rem;
		text-transform: uppercase;
		color: white;
		opacity: .6;
		margin-bottom: 0.5rem;
	}
	hr {
		margin: 1.5rem 0 2.5rem;
	}
}
.gezondwerken__detail .gezondwerken__detail-header {
	hr {
		margin: 1.5rem 0 2.5rem;
	}
}
.gezondwerken__detail-container .grey-bg .content {
	padding: 50px 0 !important;
}

@media screen and (min-width: 40.063em) {
	.gezondwerken__detail-container .grey-bg .content {
		padding: 100px 0 !important;
	}
}

.gezondwerken__detail-content {
	padding: 50px 0;
	@include breakpoint(medium) {
		padding: 6.25rem 2.1875rem 6.25rem 0;
	}
}
.gezondwerken__detail-sidebar {
	height: 100%;
	padding: 50px 15px;
	background: -moz-linear-gradient(left, rgba(128, 128, 128, 0.09) 0%, rgba(125, 185, 232, 0) 100%);
	background: -webkit-linear-gradient(left, rgba(128, 128, 128, 0.09) 0%, rgba(125, 185, 232, 0) 100%);
	background: linear-gradient(to right, rgba(128, 128, 128, 0.09) 0%, rgba(125, 185, 232, 0) 100%);
	aside {
		padding: 0;
		> h3 {
			line-height: 1;
			margin-bottom: 20px;
		}
		.swirl {
			margin-top: 2.5rem;
		}
		.overview__list {
			padding-top: 0;
		}
		.overview__list-item {
			a {
				padding-right: 0;
				&::after {
					display: none;
				}
			}
			h3 {
				font-size: 1.125rem;
				margin-bottom: 0.125rem;
			}
		}
	}
	@include breakpoint(medium) {
		padding: 100px 15px;
		border-left: 1px solid #D5D5D5;
	}
	@include breakpoint(large) {
		padding: 6.25rem 0 6.25rem 3.125rem;
	}
}
.gezondwerken__detail .tag-list {
	li {
		list-style: none;
		display: inline-block;
		margin-bottom: 8px;
		margin-right: 8px;
	}
	span {
		border-radius: .1875rem;
		padding: .5rem .9375rem .625rem;
		display: inline-block;
		font-size: 1rem;
		color: #252525;
		font-weight: 300;
		border: 1px solid #C7C7C7;
		&::after {
			display: none;
		}
	}
}