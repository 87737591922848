.navbutton {
    cursor: pointer;
    background-size: 175px 348px;
    height: 58px;
    border: none;
    background-position: 0 -58px;
    font-size: 15px;
    font-weight: 600;
    color: $primary-color !important;
    display: inline-block;
    @include transition(transform);
    &:hover, &:focus {
        color: $primary-color;
        background-position: 0 -116px;
        transform: scale(1.05);
    }
    @include breakpoint(medium) {
        font-size: 16px;
    }
}

.separator {
    width: 100%;
    height: 2px;
    color: $pink;
    margin: rem-calc(23) 0;
    background: #E02F4A;
}
.heighter {
    height: 160px;
}

#surveyElement {
	.sv {
		&_main {
			width: 100%;
			background: transparent;

			@include breakpoint(medium) {
			}
		}
		&_container, &_body, &_row, &_progress{
			background: transparent;
		}
		&_body {
			position: relative;
		}
		&_progress{
			text-align: left;
			.sv_progress_bar {
				white-space: nowrap;
				@include breakpoint(small only) {
					width: 100% !important;
				}
				//width: 100% !important;
				display: inline-block;
				font-weight: 700;
				position: absolute;
				left: 0;
				top: 0;
				font-size: 2rem;
				@include breakpoint(medium) {
					font-size: 2.5rem;
				}
			}
		}
	}
	.sv_qstn{
		@include breakpoint(large) {
			display: flex;
		}
	}
	.sv_custom_header {
		background-repeat: repeat-x;
		height: 2px;
        width: 100%;
		background-size: auto 12px;
		display: block;
		margin: 0 0 30px;
		@include breakpoint(medium) {
			margin: 0 0 50px;
		}
	}
	.sv_nav {
		position: relative;
		margin-top: 50px;
		margin-bottom: 40px;
		padding-top: 13px;
		text-align: center;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:before {
			content: '';
			top: 0;
			background-repeat: repeat-x;
			background-size: auto 12px;
			background-position: center top;
			position: absolute;
			left: 0;
			right: 0;
			height: 2px;
            width: 100%;
            background-color: #E02F4A;
		}
        .sv_next_btn {
            position: absolute;
            right: 0;
        }
	}
	.sv_q_erbox {
		font-size: rem-calc(18px);
		font-style: italic
	}
	.title-left,
	.content-left {
		width: 100%;
		clear: both;
		@include breakpoint(large) {
			width: 50%;
		}
	}
	.content-left {
		overflow: visible;
		@include breakpoint(large) {
			margin-top: rem-calc(50);
		}
	}
	.title-left {
		text-align: left;
	}

	.sq-title {
		font-weight: 300;
		font-size: 1.8rem;
		margin-top: rem-calc(40);
		margin-bottom: 2rem;
        color: #E02F4A;
		@include breakpoint(medium) {
			font-size: 2.5rem;
			margin-top: rem-calc(70);
		}
        > span {
            font-size: 1.3rem;
            font-weight: 500;
        }
	}
	.sq-item {
		.sq-label {
			min-height: rem-calc(78);
			color: $almost-black;
			display: block;
			padding: 15px 0 15px 50px;
			@include breakpoint(medium) {
				padding: 15px 0 15px 60px;
                font-size: rem-calc(18);
            }
			position: relative;
			cursor: pointer;
			font-size: rem-calc(16);
			font-weight: 500;
			line-height: rem-calc(24);
			background-image: url(../images/test-checkbox.svg);
			background-repeat: no-repeat;
			background-size: rem-calc(28);
			background-position: center left;
			input {
				//visibility: hidden;
				z-index: -100;
				position: absolute;
			}
			//&:before {
			//	position: absolute;
			//	top: 50%;
			//	transform: translateY(-50%);
			//	left: 0;
			//	content: '';
			//	//background-image: url(../images/exam-checkbox.svg);
			//	//background-repeat: no-repeat;
			//	width: 2.75rem;
			//	height: 2.375rem;
			//	display: inline-block;
			//}
			&:after {
				transition: all 125ms ease-in-out;
				content: '';
				left: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				//background-image: url(../images/exam-checkbox-fill.svg);
				background-repeat: no-repeat;
				width: rem-calc(28);
				display: inline-block;
				background-image: url(../images/test-checkbox-fill.svg);
				background-size: rem-calc(28);
				background-position: center center;
			}
			&:hover {
				&:after {
					background-size: rem-calc(28 28);
					opacity: 0.5;
				}
			}
			> span > span {
				position: absolute !important;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&.checked {
			.sq-label {

				&:after {
					opacity: 1;
					background-size: rem-calc(28 28);
				}
			}
		}
	}
	.sv_qstn fieldset {
		counter-reset: question-count;
		.sq-item {
			counter-increment: question-count;
			padding-right: 0;
			.sq-label {

			}
		}
	}
}
#surveyResult {
	color: $almost-black;
	#surveyResultHeader {
        text-align: left;
        h1 {
            margin-bottom: 0;
        }
		h3 {
			font-size: rem-calc(30);
			margin-bottom: 0;
			@include breakpoint(large) {
				font-size: rem-calc(40);
			}
		}
        h4 {
            font-size: 1.3rem;
            color: $pink;
            font-weight: 500;
        }
		img {
			width: 250px;
			@include breakpoint(large) {
				width: auto;
			}
			max-width: 300px;
		}
	}
}
.resultlist {
	* {
		color: $almost-black;
	}
	h4 {
		margin-bottom: 0;
        font-weight: 600;
	}
	.answer-list-result {
		background-repeat: no-repeat;
		display: block;
		width: 40px;
		height: 40px;
		@include breakpoint(medium) {
			width: 75px;
			height: 75px;
		}
	}
	.result-item {
		margin-bottom: 45px;
	}

	@media screen and (min-width: 40.063em) {
		.answer-list-result {
			margin:0
		}
	}

	.answer-list-result--false {
        -webkit-mask: url("../images/question-false.svg");
        mask: url("../images/question-false.svg");
        background-color: #333;
	}
}
.zelftest__container {
	width: 100%;
	position: relative;
	z-index: 5;
	//padding: 300px 0;
	height: calc(100vh - 76px);
	@include breakpoint(large) {
		height: calc(100vh - 115px);
	}
	.grid-container {
		top: 50%;
		position: relative;
		transform: translateY(-50%);
		z-index: 1;
	}
	&::before {
		background-image: url(../images/swirl-white.svg);
		content: '';
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.1;
		background-size: auto 15px;
		@include breakpoint(medium) {
			background-size: auto 30px;
			opacity: .06;
		}
	}
	@include breakpoint(large) {
		&::after {
			background-repeat: no-repeat;
			content: '';
			top: 0;
			right: 0;
			position: absolute;
			width: 100%;
			background-size: 550px;
			background-position: right -150px top 25vh;
			height: 100%;
			opacity: 0.5;
			@include breakpoint(xlarge) {
				opacity: 1;
				background-position: right -10% top 25vh;
			}
		}
	}

	h1, h2, h3, h4, h5, h6, p {
		color: $almost-black;
	}
	h1 {
		margin-bottom: 0;
		line-height: 1.2;
		font-size: 1.875rem;
	}
	h2 {
		line-height: 1.2;
        font-size: 1.3rem;
        color: $pink;
        font-weight: 500;
        margin-top: rem-calc(8);
    }
	@include breakpoint(large) {
		h1, h2 {
			font-size: 3.125rem;
		}
	}
	p {
		font-size: rem-calc(15);
		font-weight: 300;
        color: #747474;
	}
}
.zelftest {
	min-height: calc(100vh - 76px);
	background-color: #f9f9f9 !important;

	@include breakpoint(large) {
		min-height: calc(100vh - 115px);
		padding-top: 100px;
	}

    &__logowrapper {
        display: flex;
        justify-content: center;
        padding: 50px 0;
    }
    &__wrapper {
        display: flex;
        justify-content: center;

    }
	&__back {
		margin-top: 10rem;

		p:first-child {
			border-bottom: 2px solid var(--cc-btn-primary-bg);
			padding-bottom: 15px;
		}
	}
    &__thumbsup {
        background: url(../images/thumbs.svg) no-repeat transparent;
        background-size: contain;
        background-repeat: no-repeat;
        max-width: 300px;
        width: 100%;
        height: 300px;
    }

    .sv_qstn {
        overflow: inherit;
    }
}

.result-item {
	width: 100%;

	&__number {
		color: var(--cc-toggle-bg-on);
		font-weight: bold;
	}

	&__title {
		margin-bottom: 15px;
	}

	&__readmore {
		text-decoration: underline;
		color: var(--cc-toggle-bg-on);

		strong {
			color: inherit;
		}

		&:hover {
			text-decoration: none;
			color: inherit;
		}
	}
}

.questions {
	counter-reset: listStyle;
}

.question {
	background: #fff;
	border-radius: 25px;
    padding: 10px 15px;
    border: 2px solid transparent;
    margin-bottom: 15px;
	counter-increment: listStyle;
	position: relative;

	&--selected {
		border-color: var(--cc-toggle-bg-on);

		&:after {
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18.111" height="18.111" viewBox="0 0 18.111 18.111"><g id="Group_514" data-name="Group 514" transform="translate(-1029.874 -1307.373)"><line id="Line_23" data-name="Line 23" y2="21.212" transform="translate(1046.428 1308.929) rotate(45)" fill="none" stroke="%23f05364" stroke-linecap="round" stroke-width="2.2"/><line id="Line_24" data-name="Line 24" y1="21.212" transform="translate(1046.429 1323.928) rotate(135)" fill="none" stroke="%23f05364" stroke-linecap="round" stroke-width="2.2"/></g></svg>');			
		}
	}

	&:after {
		content: '';
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		width: 20px;
		height: 20px;
		position: absolute;
		right: 20px;
		top: 50%;
    	transform: translateY(-50%);
	}

	&--correct {
		&:after {
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20.981" height="15.719" viewBox="0 0 20.981 15.719"><path id="Path_195" data-name="Path 195" d="M10.3,18.748,5.923,14.373a1.237,1.237,0,0,0-1.75,1.75l5.238,5.238a1.245,1.245,0,0,0,1.762,0L24.423,8.123a1.237,1.237,0,0,0-1.75-1.75Z" transform="translate(-3.808 -6.008)" fill="%23f05364"/></svg>');
		}
	}

	&:before {
		content: counter(listStyle, upper-alpha) + '.';
		margin-right: 12px;
		font-weight: bold;
	}
}
 
.status__message {
	color: var(--cc-toggle-bg-on);
}

.read-more {
	margin-bottom: 24px;
	border-bottom: 1px solid #f2f2f2;
	padding-bottom: 24px;

	p {
		margin: 8px 0 15px;
	}

	.btn {
		border-radius: 25px;
		display: inline-flex;
		align-items: center;
		gap: 6px;
	}
}