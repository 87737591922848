.block__one-column-pink {
  background-color: #E9485D;
  padding: 140px 0;
  color: $white;
  text-align: center;
  position: relative;

  &::before {
    background-image: url(../images/swirl-white.svg);
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-size: auto 15px;
    pointer-events: none;
    @include breakpoint(medium) {
      background-size: auto 30px;
      opacity: .06;
    }
  }

  a {
    margin-top: 40px;
    @include breakpoint(small down) {
      margin-top: 20px;
    }
  }

  @include breakpoint(medium down) {
    padding: 100px 0;
  }
  @include breakpoint(small down) {
    padding: 60px 0;
  }

  .btn-primary {
    color: $black;
  }
}