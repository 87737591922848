.custom-dropdown-wrapper {
    position: relative;
    height: 50px;
}


.custom-dropdown {
    background-color: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;


    .selector, .item {
        color: $black;
        display: block;
        padding: rem-calc(12 20);
        font-size: rem-calc(18);
    }

    .selector {
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='arrow_right_black_24dp_1_' data-name='arrow_right_black_24dp (1)' transform='translate(24) rotate(90)'%3E%3Cpath id='Path_127' data-name='Path 127' d='M0,24H24V0H0Z' fill='none'/%3E%3Cpath id='Path_128' data-name='Path 128' d='M1.71.3,4.3,2.886a1,1,0,0,1,0,1.41L1.71,6.886A1,1,0,0,1,0,6.176V1.006A1,1,0,0,1,1.71.3Z' transform='translate(10 8.414)' fill='%23e9485d'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 10px center;
        cursor: default;
    }

    .item {
        display: none;
        &:hover {
            color: $primary-color;
        }
    }


    &:hover {
        .selector {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='arrow_right_black_24dp_1_' data-name='arrow_right_black_24dp (1)' transform='translate(0 24) rotate(-90)'%3E%3Cpath id='Path_127' data-name='Path 127' d='M0,24H24V0H0Z' fill='none'/%3E%3Cpath id='Path_128' data-name='Path 128' d='M1.71.3,4.3,2.886a1,1,0,0,1,0,1.41L1.71,6.886A1,1,0,0,1,0,6.176V1.006A1,1,0,0,1,1.71.3Z' transform='translate(10 8.414)' fill='%23e9485d'/%3E%3C/g%3E%3C/svg%3E%0A");
            color: $primary-color;
        }
        .item {
            display: block;
        }
    }
}
