.block__one-column-image {
	position: relative;
	z-index: 0;
	//border-bottom: 2px solid #EDEDED;
	&-background {
		height: 100%;
		width: 100%;
		opacity: 0.5;
		@include breakpoint(large) {
			width: 50%;
			opacity: 1;
		}
		position: absolute;
		top: 0;
		z-index: 1;
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-size: cover;
		&.right {
			right: 0;
		}
		&.left {
			left: 0;
		}
		@include breakpoint(medium) {
			&.image-left {
				background-position: left bottom;
			}
			&.image-right {
				background-position: left top;
			}
		}
	}
	.grid-container {
		z-index: 2;
	}

	&.webinar-header {
		.block__one-column-image-background {
			background-position: right bottom;
			width: 40%;
			background-size: contain;
			margin-top: 50px;
			@include breakpoint(medium down) {
				margin-top: 0;
			}
		}

		.pink {
			color: #E9485D;
		}
	}
}
