@import "footer";
@import "google-map";
@import "logo-list";
@import "page-header";

.swirl {
	border: none;
	//background-image: url("../images/swirl.svg");
	background-image: url(../images/swirl1.svg);
	background-repeat: repeat-x;
	height: 16.5px;
	background-size: auto 13px;
}

.swirl--grey {
	background-image: url("../images/swirl-grey.svg")
}