[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
	margin-bottom: 0;
}


.form-group {
	margin-bottom: rem-calc(20px);
	position: relative;
	label {
		position: absolute;
		top: 0;
		opacity: 0;
		color: $white;
		background: $primary-color;
	}
}

.block__renatioform {
	background: $primary-color;
	color: $white;


	label {
		color: $white;
	}


	padding: 50px 0;

	* {
		color: white;
	}

	form {
		margin-top: 25px;
	}



	input, textarea {
		background: transparent;
		border: 2px solid rgba(white, .5);
		color: white !important;
		padding: 5px 15px;
		font-style: italic;
		font-weight: 500;
		line-height: 24px;
		height: auto;

		&:focus {
			border: 2px solid rgba(white, 1);
			background: transparent;
		}

		&::placeholder {
			color: white !important;
			opacity: 1;
		}
	}

	input {
		line-height: 36px;
	}
	textarea {
		padding: 10px 15px;
		resize: vertical;
		min-height: 150px;
	}

	.submit {
		background: white;
		color: $primary-color;
		margin: 0 auto;
		display: block;
	}
	.is-invalid-input:not(:focus) {
		background: transparent;
		border-color: red;
	}

	&.webinar-form {
		background: $white;

		.form__success {
			color: #E9485D !important;
			text-align: center !important;
		}

		&::before {
			width: 250px;
			height: 434px;
			position: absolute;
			content: '';
			background-image: url(../images/webinar_woman.png);
			background-size: contain;
			background-repeat: no-repeat;
			pointer-events: none;
			left: 50px;
			@include breakpoint(medium down) {
				display: none;
			}
		}

		h2,p {
			color: $black;
			font-weight: 300;
			text-align: center;
		}
		p {
			margin-bottom: 40px;
		}
		label {
			color: $black;
		}

		input {
			background: transparent;
			border: 2px solid rgba(black, .5);
			color: black !important;

			&:focus {
				border: 2px solid rgba(black, 1);
				background: transparent;
			}

			&::placeholder {
				color: black !important;
				opacity: 1;
			}
		}

		.button--pink {
			background-color: $black;
			text-align: center;
			display: block;
			margin: auto;
		}

		#aanmelden {
			position: absolute;
			top: -300px;
			@include breakpoint(small down) {
				top: -200px;
			}
		}
	}


}
form.sending {
	.form-group {
		opacity: 0.5;
	}
}
.form__container {
	position: relative;
	z-index: 0;
	.form__loader {
		z-index: -1;
		opacity: 0;
		//background-color: rgba($white, 0.7);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@include transition(all);
		svg {
			width: 50px;
			height: 50px;
			@include horizontal-center();
			top: 30%;
		}
	}
	&.sending {
		.form__loader {
			z-index: 1;
			opacity: 1;
		}
	}
}

.mc-field-group{
    padding-bottom: 15px !important;
    &.input-group{
        display: block;
        margin-bottom: 0;
        strong,ul{
            display: block;
            width:100%;
        }
    }
}
#mc-embedded-subscribe-form{
    button{
        margin:0;
    }
    padding-bottom: 100px !important;
}

.healthy-hairdresser-form {
	margin-top: -80px !important;
}