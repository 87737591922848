.content-bar {
	padding: 23px 10px 20px;
	margin: 0;
	position: relative
}

.content-bar.rie-content-bar::before {
	display: none
}

.content-bar::before {
	width: 1px;
	height: 100%;
	background: #ccc;
	position: absolute;
	left: 50%;
	top: 0;
	content: ''
}

.content-bar .social-list {
	font-size: 18px
}

.content-bar .social-list span,.content-bar .social-list a {
	font-weight: 300;
	font-style: italic;
	margin-right: 10px
}

.content-bar .social-list a {
	display: inline-block;
	position: relative
}

.content-bar .social-list a span {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5
}

.content-bar a.return-link {
	position: relative;
	padding-left: 20px;
	display: inline-block;
	color: #262626;
	font-style: italic;
	font-size: 18px;
	font-weight: 300
}

.content-bar a.return-link:hover {
	color: $primary-color !important
}

.content-bar a.return-link::before {
	content: url(../images/ico-arrow-grey.svg);
	position: absolute;
	top: -5px;
	left: 0;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg)
}

.content-bar a.return-link:hover::before {
	content: url(../images/ico-arrow-pink.svg)
}

.content-bar a.next-link {
	position: relative;
	padding-right: 20px;
	display: inline-block;
	color: #262626;
	font-style: italic;
	font-size: 18px;
	font-weight: 300
}

.content-bar a.next-link:hover {
	color: $primary-color !important
}

.content-bar a.next-link::after {
	content: url(../images/ico-arrow-grey.svg);
	position: absolute;
	top: 0px;
	right: 0
}

.content-bar a.next-link:hover::after {
	content: url(../images/ico-arrow-pink.svg)
}

@media only screen and (max-device-width: 40em) {
	.content-bar {
		//text-align:center
	}

	.content-bar .text-right {
		text-align: center !important
	}

	.content-bar::before {
		display: none
	}

	.content-bar a.return-link {
		margin-bottom: 15px
	}
}

.related {
    &__container {
        margin: 80px 0;
    }

    &__title {
        padding: 0 24px 32px;
        border-bottom: 1px solid #ccc;
        font-weight: 700;
        font-size: rem-calc(30);
        margin-bottom: 32px;

        @include breakpoint(medium down) {
            padding: 0 .9375rem 32px;
        }
    }

    &__items {
        display: flex;

        @include breakpoint(medium down) {
            flex-direction: column;
        }
    }

    &__item {
        padding: 0 24px;

        @include breakpoint(medium down) {
            margin: 8px 0;
            border-bottom: 1px solid #f2f2f2;
            padding: 0 .9375rem;
        }

        &-title {
            h3 {
                text-transform: uppercase;
                font-weight: 700;
                font-size: rem-calc(18);
                transition: color .2s ease-in-out;
            }
        }

        &-container {
            padding-right: 12px;
            flex: 2;
        }

        &-link {
            color: $black;
            display: flex;
            align-items: flex-start;

            &:hover {
                color: $black;

                h3 {
                    color: $primary-color;
                }

                .related__cta {
                    & {
                        background: $primary-color;
                    }

                    &::before {
                        background-position: left 0;
                    }

                    &::after {
                        background-position: right 0;
                    }

                    span {
                        background: no-repeat center url(../images/white-arrow--right.svg);
                        transform: translateX(10px);
                    }
                }
            }
        }
    }

    &__cta {
        height: 58px;

        &:hover {
            transform: none;
        }

        span {
            position: absolute;
            z-index: 2;
            width: 16px;
            height: 16px;
            left: 7px;
            background: no-repeat center url(../images/primary-arrow--right.svg);
            background-size: 100%;
            transition: transform .3s ease-in-out;
        }
    }
}

div.blog-detail-header {
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 15px 0;
    transition: all .2s ease-in-out;
    transform: translateY(-100%);

    &.sticky-header {
        padding: 15px 0;
        transform: translateY(0);

        @include breakpoint(medium down) {
            top: 76px;
        }
    }
}

.blog-container.blog-detail {
	border-top: 0;
}

.blog-container.blog-detail .primary-content-left::after {
	display: none
}

.blog-container.blog-detail .content-bar {
	padding: 35px 10px 30px
}

.blog-container.blog-detail .zelftest-popup {
	display: block;
	padding: 33px 20px 30px;
	background: $primary-color;
	@include breakpoint(medium) {
		//margin-right: 115px;
		padding: 33px 30px 30px;
	}
}

.blog__item-date {
    display: block;
    margin: 24px 0 48px;
    font-size: rem-calc(20);
}

.blog-container.blog-detail .zelftest-popup h6,.blog-container.blog-detail .zelftest-popup h5 {
	font-size: 24px;
	color: white;
	margin: 0;
	line-height: 28px
}


.blog-container {
	//border-top: 1px solid #bdbdbd;
	padding: 45px 0
}

.blog-container ul.blog-items {
	margin-top: 0
}

.blog-container ul.blog-items li {
	//width: 50%
}

.blog-container ul.blog-items li:first-child {
	//width: 100%
}

.blog-container .latest-blog {
	border-top: 1px solid #bdbdbd;
	position: relative
}

.blog-container .latest-blog::before {
	position: absolute;
	top: 0;
	width: 5000px;
	right: 100%;
	content: '';
	background: #bdbdbd;
	height: 1px
}

.blog-container .latest-blog h3 {
	color: $primary-color;
	border-top: 5px solid $primary-color;
	padding: 17px 10px 0px;
	margin-bottom: 0;
	font-size: 24px;
	display: inline-block
}

.blog-container ul.blog-latest {
	margin-top: 0;
	margin-bottom: 0
}

.blog-container ul.blog-latest li {
	//width: 50%
}

.latest-subject {
	position: relative
}

.latest-subject h3 {
	color: #262626;
	padding: 17px 10px 0px;
	margin-bottom: 0;
	font-size: 24px;
	display: inline-block
}

@media only screen and (max-device-width: 40em) {
	.blog-container ul.blog-items li {
		//width:100%
	}

	.blog-container ul.blog-items li:nth-of-type(2) {
		//width: 100% !important
	}

	.blog-container .latest-blog {
		border-top: 0px solid #bdbdbd
	}

	.blog-container .latest-blog h3 {
		border-top: 5px solid $primary-color;
		display: block;
		text-align: center
	}

	.blog-container ul.blog-latest li {
		width: 100%
	}
}

.blog-container.blog-detail {
	padding-bottom: 0;
	position: relative;
	z-index: 0;
	//overflow-x: hidden;
}

.blog__detail-header {
	padding: 30px 0 !important;
}

@media screen and (min-width: 40.063em) {
	.blog__detail-header {
		padding: 50px 0 !important
	}
}
