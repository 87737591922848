.topbar {
  &__container {
    z-index: 50;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 76px;
    @include breakpoint(large) {
      height: 115px;
    }
    @include transition(all);
    @include breakpoint(medium down) {
      position: relative;
    }
    .grid-container {
      //max-width: none;
      position: relative;
    }
    .personal-code{
        margin-bottom: 0;
    }
  }

  .logo {
    display: block;
    @include transition(all);
    width: 105px;
    @include breakpoint(medium) {
      width: 120px;
    }
    @include breakpoint(large) {
      width: 170px;
    }
    max-width: 170px;
    svg {
      width: 100%;
      height: auto;
      display: block;
      @include transition(all);
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__wrapper {
    //height: rem-calc(88);
    //padding: 80px 30px 0;
    padding: rem-calc(14px 0);
    background: $primary-color !important;
    height: 6rem;

    @include transition(all);
    @include breakpoint(medium down) {
      position: fixed !important;
      top: 0 !important;
      width: 100%;

      padding: rem-calc(5px 0);
      height: auto;
      box-shadow: 0 -25px 20px 10px #000000;
    }


    &.is-stuck {
      @include breakpoint(medium down) {
        box-shadow: 0 -15px 20px 10px #000000;
      }
      @include breakpoint(large) {
        height: 4.6rem;
        padding: 10px 0;
      }

      .logo {
        width: 130px;
      }

      .menu__firstlevel-item.hover > ul.submenu {
        transform: translateY(19px);
      }
    }
  }

  .menu__icon {
    fill: $white;
    transition: .3s ease-in-out;
    width: 14px;
    height: 14px;
    margin-top: 3px;
  }

  .menu-wrapper {
    .submenu { 
      opacity: 0;
      visibility: hidden;
      // padding: 0;
      list-style-type: none;
      text-align: left;

      a {
        white-space: nowrap;
      }
    }

    .menu__item {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .hover {
      & > .menu__item {
        .menu__icon {
          transform: rotate(180deg);
        }
      }
    }

    .menu__firstlevel-item {
      position: relative;

      .is-stuck & {
        background-color: red;
      } 

      > .submenu {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        transform: translateY(-10px);
        background-color: #EDEDED;
      }

      &.hover {   
        & > .submenu {
          padding: 1rem;
          opacity: 1;
          visibility: visible;
          transform: translateY(30px);
        }
      }
    }

    .menu__secondlevel-item {
      .menu__icon {
        fill: $secondary-color;
      }

      .submenu {
        position: relative;
        max-height: 0;
        padding: 0;
        transition: all 0.3s ease;
        overflow: hidden;

        a {
          font-weight: normal;
        }
      }

      &.hover {
        .submenu {
          max-height: 100px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    
    > .menu {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      > li {
        & + li {
          @include breakpoint(1140) {
            margin-left: rem-calc(10);
          }
        }
      }

      .menu__cta {
        a {
          background: $secondary-color;
          color: $white;
          padding: 1rem 2rem;
          border-radius: 30px;
          transition: all .2s ease-in-out;

          &:hover {
            background: $white;
            color: $secondary-color;
          }
        } 
      }

      a {
        //height: rem-calc(62.75);
        color: $white;
        font-weight: 600;
        font-size: rem-calc(18);
        //padding: 0.7rem 0.5rem;

        @include breakpoint(1140) {
          //padding: 0.7rem 1rem;
        }
        position: relative;



        &:hover {
          color: $white;
          text-decoration: none;
        }
      }

      .submenu a {
        color: $secondary-color;
      }

      .is-active {
        a {
          background: transparent;
          color: $white;
        }

        .submenu a {
          color: $secondary-color;

          &:hover {
            color: $primary-color;
          }
        }
      }

    }

    .button {
      float: right;
      margin-left: rem-calc(10);
      @include breakpoint(1140) {
        margin-left: rem-calc(30);
      }
    }
  }

  &__wrapper {
    &.is-stuck {
      .menu {
        a {
        }
      }
    }
  }

  &__submenu {
    background: $secondary-color;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;

    @include breakpoint(large) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    ul {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      gap: 30px;
      padding: 0;
      margin: 0;
    }

    a {
      font-size: rem-calc(18);
      color: $white;
    }
  }
}

.svg-menu-toggle {

  fill:#fff;
  pointer-events:all; //needs to be there so the hover works
  cursor:pointer;

  .bar {

    -webkit-transform:rotate(0) translateY(0) translateX(0);
    transform:rotate(0) translateY(0) translateX(0);
    opacity:1;


    -webkit-transform-origin: 20px 10px;
    transform-origin: 20px 10px;

    -webkit-transition: -webkit-transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

  }
  .bar:nth-of-type(1) {
    -webkit-transform-origin: 20px 10px;
    transform-origin: 20px 10px;

  }
  .bar:nth-of-type(3) {
    -webkit-transform-origin: 20px 20px;
    transform-origin: 20px 20px;

  }


}
.menu-button {
  width: 40px;
  height: 40px;
  border-radius: 0;
  padding: 0;
  position: relative;
  right: 0;
  top: 3px;
  display: inline-block;
  vertical-align: top;
  .menu-icon {
    position: absolute;
    left: 11px;
    top: 9px;
    span {
      opacity: 1;
      display: block;
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: $white;
      margin: 4px 0;
      @include transition(all);
    }
  }
  .icon {
    position: absolute;
    top: 12px;
    left: 12px;
    color: $white;
    opacity: 0;
    @include transition(all);
    width: 16px;
    height: 16px;
    &.icon-cross {
      background-image: url('../images/icon-cross.svg');
      background-size: cover;
    }
  }
}
.off-canvas-content {
  //transition: all 0.5s ease;
  //overflow: hidden;
  //overflow-y: auto;
  //-webkit-overflow-scrolling: touch;
}
.off-canvas.position-right {
  &:after {
    content: '';
    position: absolute;
    left: -80px;
    z-index: -1;
    top: 0;
    bottom: 0;
    width: 80px;
    @include linear-gradient--horizontal(rgba(0,0,0,0), rgba(0,0,0,0.19));
    pointer-events: none;
  }

  padding-top: 70px;

  .menu__itemwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-weight: bold;
    }
  }

  .submenu {
    transition: .2s ease-in-out;
    border: none;
    padding-top: 10px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    margin: 0;
  }

  .menu__icon {
    transform: rotate(-90deg);
    transition: .2s ease-in-out;
  }

  .is-active {
    & > .menu__itemwrapper {
      color: $primary-color;

      a {
        color: inherit;
      }

      path {
        fill: currentColor;
      }

      .menu__icon {
        transform: rotate(0deg);
      }
    }

    & > .submenu {
        max-height: 600px;
        opacity: 1;
        visibility: visible;
    }
  }

  .submenu li {
    padding-left: 15px;
  }

  .menu__cta {
    margin-top: 1rem;

    a {
      background: $white;
      color: $primary-color;
      padding: 1rem 2rem;
      border-radius: 30px;
      transition: all .2s ease-in-out;

      &:hover, &:focus {
        background: $primary-color;
        color: $white;
      }
    } 
  }

  .menu__secondlevel-item {
    padding-left: 15px;

    .submenu {
      a {
        font-weight: normal;
      }

      li {
        padding-left: 0;
      }
    }
  }
}

.position-right {
  width: 100%;
  transform: translateX(100%);
}

.is-off-canvas-open {
  .menu-button {
    .menu-icon {
      span {
        opacity: 0;
      }
    }

    .icon {
      opacity: 1;
    }
  }
  .off-canvas-content {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transform: translateX(-150px);
  }
}
.off-canvas.position-right.is-open {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.js-off-canvas-overlay {
  overflow: hidden;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  z-index: -1;
  &.is-visible {
    z-index: 10;
  }
}
.off-canvas {
  overflow: hidden;
  overflow-y: auto;
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    //overflow: hidden;
    //overflow-y: auto;
    touch-action: pan-y;
    //-webkit-overflow-scrolling: touch;
    padding-top: 28px;
    padding-bottom: 30px;
    @include breakpoint(medium) {
      padding-top: 48px;
      padding-bottom: 50px;
    }

  }
  .menu-wrapper {
    ul {
      margin: 0;
      font-size: rem-calc(18);
      font-weight: 500;
      list-style: none;
      li {
        > ul {
          margin-left: 20px;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 400;
        }
        & + li {
          padding-top: 5px;
        }
      }
    }
    a {
      color: #d2d2d2;
      display: block;
      padding: 5px 0;
      font-size: 18px;
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    > li.active {
      > a {
        color: $white;
        position: relative;
        //&:after {
        //  content: '';
        //  position: absolute;
        //  bottom: 0px;
        //  left: 10px;
        //  width: 42px;
        //  height: 4px;
        //  background-image: url(../images/svg/dot-line-dark.svg);
        //  background-position: left center;
        //  background-repeat: repeat-x;
        //  background-size: 11px 4px;
        //  opacity: 1;
        //  @include transition(all, 250ms);
        //}
      }
    }
  }
}

.searchicon {
  display: inline-block;
  cursor: pointer;
  width: rem-calc(40);
  height: rem-calc(40);
  background-image: url(../images/search.svg);
  background-size: rem-calc(20 20);
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events:all;
  -webkit-tap-highlight-color: transparent;
  top: 3px;
  position: relative;
  @include breakpoint(large) {
    background-size: rem-calc(16 16);
    width: rem-calc(32);
    height: rem-calc(32);
    //top: auto;
  }
}
.topbar__wrapper.is-stuck {
  .searchbar {
    //top: 70px;
  }
}

.off-canvas{
    .menu-wrapper{
        .dropdown-pane{
            width: 210px;
            padding-bottom:30px;
            h5{
                font-size: 15px;
                font-weight: 700;
            }
            a{
                background: $primary-color;
                color:white;
                position:absolute;
                bottom:0;
                width:100%;
                text-align: center;
                left: 0;
            }
        }
        .personal-code{
            word-break: break-all;
            text-align: center;
        }
    }

    .mobile__submenu {
      margin-top: 48px;
      border-top: 1px solid #545151;
      padding-top: 24px;
      font-size: 90%;

      a {
        font-size: 16px;
      }
    }
}
