.footer {
	background: $secondary-color;
	position: relative;
	z-index: 1;
	width: 100%;
	color: $white;

	p {
		//margin-bottom: rem-calc(28);
	}

	a {
		color: $white;
	}

	@include breakpoint(large only) {
		.xlarge-order-4 {
			padding-bottom: rem-calc(50px);
		}
	}

	ul {
		margin: 0 0 1rem;
		padding: 0;
		list-style-type: none;

		li {
			margin: rem-calc(0 0 5px);
			padding: 0;
			color: inherit;
		}

		&.social-links {
			padding: rem-calc(12px 0 0);
			@include breakpoint(large) {
				padding: rem-calc(65px 0 0);
			}

			li {
				display: inline-block;
				margin-right: rem-calc(16);

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	hr {
		margin: 33px 0;
		border: none;
		border-bottom: 1px solid #464646;
	}
	.logo {
		svg {
			max-width: rem-calc(107px);
			height: auto;
		}

	}
	@include breakpoint(medium down) {
		h2 {
			font-size: 28px;
		}
	}
	&__top {
		padding-top: 30px;
		@include breakpoint(medium) {
			padding-top: 60px;
		}
		h4 {
			font-weight: 500;
			margin-bottom: .5em;
		}
		a {
			color: #a7a7a7;
			&:hover {
				color: $white;
			}
		}
		//
		//@include breakpoint(medium down) {
		//	padding-top: 80px;
		//}
		//padding-bottom: 40px;
		//@include breakpoint(medium) {
		//	padding-bottom: 80px;
		//}
		//
		//@include breakpoint(large down) {
		//	.grid-x > .cell {
		//		padding-bottom: rem-calc(50px);
		//	}
		//}
		//@include breakpoint(small only) {
		//	.grid-x > .cell {
		//		padding-bottom: rem-calc(20px);
		//	}
		//}
	}
	&__bottom {
		border-top: 1px solid rgba(209,210,212, 0.2);
		line-height: 1.625;
		background: $white;
		color: #a7a7a7;
		padding-bottom: 25px;
		p {
			margin-bottom: 0;
		}
		a {
			color: $primary-color;
		}
		.copyright {
			text-align: center;

			@include breakpoint(medium) {
				float: left;
				text-align: left;
				margin-right: rem-calc(30);
			}
		}
		ul {
			@include breakpoint(small only) {
				justify-content: center;
			}
			li {
				@include breakpoint(small only) {
					display: flex;
					justify-content: center;
				}
				margin-bottom: 0;
				a {
					opacity: 0.82;
					display: initial;
					line-height: inherit;
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
}