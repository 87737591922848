@charset 'utf-8';

@import 'init/_init';

@import 'settings';

@import 'init/loops';

@import '../../node_modules/foundation-sites/scss/foundation';


$slick-font-family: "" !default;
$slick-loader-path: "../images/" !default;
$slick-prev-character: "\02190" !default;
$slick-next-character: "\02192" !default;
$slick-arrow-color: black !default;

@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";


@include foundation-flex-classes;
@include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-global-styles;
@include foundation-accordion;
@include foundation-button;
@include foundation-card;
@include foundation-callout;
@include foundation-forms;
@include foundation-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-reveal;
@include foundation-title-bar;
@include foundation-top-bar;
@include foundation-off-canvas;
@include foundation-responsive-embed;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-tabs;
@include foundation-sticky;
@include foundation-typography;

@import 'base/_base';
@import 'modules/_modules';
@import 'layout/_layout';

html {
  scroll-behavior: smooth;
}