.faq-list {
	margin: 0;
	padding-top: 2.0625rem;
	border-bottom: 2px solid #E6E6E6
}

.faq-list__item {
	list-style-type: none;
	border-top: 2px solid #E6E6E6;
	padding: 2.5rem 0 2.1875rem;
	width: 100%
}

.faq-list__breadcrumbs {
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	display: block;
	color: $primary-color;
}

.faq-list h3 {
	transition: all 150ms ease-in-out;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 8px;
	color: #262626;
}

.faq-list p {
	color: #252525
}

.faq-list a {
	display: block;
	position: relative;
	color: $primary-color;
}

.faq-list a:hover h3 {
	color: $primary-color
}

.faq .grey-bg .content {
	padding: 50px 0 !important
}

@media screen and (min-width: 40.063em) {
	.faq .grey-bg .content {
		padding:100px 0 !important
	}
}

.faq__content {
	padding: 3.125rem 0
}

@media screen and (min-width: 40.063em) {
	.faq__content {
		padding:6.25rem 2.1875rem 6.25rem 0
	}
}

.faq__sidebar {
	height: 100%;
	padding: 3.125rem .9375rem;
	background: linear-gradient(to right, rgba(128,128,128,0.09) 0%, rgba(125,185,232,0) 100%)
}

@media screen and (min-width: 40.063em) {
	.faq__sidebar {
		padding:6.25rem 3.125rem;
		border-left: 1px solid #D5D5D5
	}
}

@media screen and (min-width: 64.063em) {
	.faq__sidebar {
		padding:6.25rem 0 6.25rem 3.125rem
	}
}

.faq__sidebar aside>h3 {
	margin-bottom: 20px;
	line-height: 1
}

.faq__sidebar aside .swirl {
	margin-top: 2.5rem
}

.faq__sidebar aside .overview__list {
	padding-top: 0
}

.faq__sidebar aside .overview__list-item a {
	padding-right: 0
}

.faq__sidebar aside .overview__list-item a::after {
	display: none
}

.faq__sidebar aside .overview__list-item h3 {
	font-size: 1.125rem;
	margin-bottom: .125rem
}
.faq__sidebar-filter {
	h3 {
		font-weight: 700;
		@include breakpoint(medium) {
			margin-bottom: 33px
		}
	}

	ul {
		//margin: 0
		@include breakpoint(medium) {
			margin-right: 40px;
		}
	}

	li {
		padding: 0 0 5px;
		display: inline-block;
		width: 49%;
		@include breakpoint(medium) {
			display: block;
			width: 100%;
		}
	}
	li:first-child {
		width: 100%;
	}

	label {
		color: #B7B7B7;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		font-family: "Exo 2", sans-serif
	}

	a {
		font-size: 16px;
		font-weight: 500;
		color: #525252;
		position: relative;
		line-height: 40px;
		//padding-left: 33px;
		padding: 0 10px;
		display: block;
		&:after {
			transition: all 150ms ease-in-out;
			width: 40px;
			height: 40px;
			top: 0px;
			right: 0px;
			position: absolute;
			content: '';
			background-image: url("../images/primary-arrow--right.svg");
			background-repeat: no-repeat;
			background-size: 13px;
			background-position: center center;
			opacity: 0;
		}

		&:hover {
			background: $light-gray;
			&:after {
				opacity: 1;
			}
		}

		&.active {
			color: $white;
			background: $primary-color;
			&:after {
				opacity: 1;
				background-image: url("../images/white-arrow--right.svg");
			}
		}
	}
}
.faq__cards {
	.card {
		@include transition(all);
		img {
			@include transition(all);
		}
		&:hover {
			transform: scale(1.02);
			border-color: $dark-gray;
			img {
				transform: scale(1.2);
			}
		}
	}
}