$white: #FFFFFF;
$black: #000000;
$primary-color: #e9485d;
$secondary-color: #262626;
$tertiary-color: #ADDFE9;
$springwood: #FAF7F4;
$header-color: $tertiary-color;
$light-gray: #f6f6f6;
$medium-gray: #D1D2D4;
$dark-gray: #42454A;
$global-shadow: 5px 5px 15px rgba(0,0,0,0.16);
$colors: (
	'white' : $white,
	'black' : $black,
	'pink' : $primary-color,
	'red' : $secondary-color,
	'blue' : $tertiary-color,
	'dark-gray': $dark-gray,
	'medium-gray': $medium-gray,
	'light-gray': $light-gray
);