[class*="button--"] {
	@include button-base();
	font-weight: 700;
	font-size: 1rem;
	border: rem-calc(1.5px) solid transparent;
	padding: rem-calc(10 20);
	line-height: inherit;
	@include breakpoint(small only) {
		font-size: rem-calc(14);
		padding: 1.16em 1.6em;
	}

	&:hover,&:focus {
		transform: scale(1.05);
		box-shadow: 5px 5px 25px rgba(0,0,0,0.16)
	}
	@media (hover: none) and (pointer: coarse) {
		&:hover {
			transform: none;
			box-shadow: none;
		}
		&:focus {
			transform: scale(1.05);
			box-shadow: 5px 5px 25px rgba(0,0,0,0.16)
		}
	}
	&.small {
		padding: 0.9em 1em;

	}
	&.has-icon {
		.icon {
			float: right;
			font-size: 20px;
			margin-top: -4px;
		}
	}
    &.smaller {
        padding: 8px 10px;
        font-weight: normal;
        margin-top: rem-calc(15);
    }
    &.transparent {
        &:hover {

        }
    }
	&[disabled=disabled] {
		&:hover, &:focus {
			transform:none;
			box-shadow: none;
		}
	}
}
[data-whatinput="touch"] .button {
	&:hover, &:focus {
		transform: none;
	}
}
@each $colorName, $colorValue in $colors {
	.button--#{$colorName}{
		@include transition(all);
		@if $colorName == 'white' {
			color: $dark-gray;
			background:$white;
		}@else {
			background:$colorValue;
			color:$white;
		}
		&:hover,&:focus{
			background:#{$colorValue};
			@if $colorName == 'white' {
				color: $dark-gray;
				background-color: darken($white,5%);
			}@else {
				color: $white;
				background-color: darken($colorValue,3%)
			}
		}
		&.hollow {
			background: transparent !important;
			border-color: $colorValue;
			color: $colorValue;
			&:hover,&:focus {
				border-color: $colorValue;
				color: $colorValue;
			}
		}
	}
}
.button--white {
	&.hollow {
		border-color: $white;
		color: $white;
		&:hover,&:focus{
			border-color: $white;
			color: $white;
			background-color: rgba(255,255,255,0.1);
			//border-color: darken($colorValue, 15%);
			//color: darken($colorValue,15%);
		}
	}
}
p,
.buttons {
	.button {
		& + .button {
			margin-left: rem-calc(20);
		}
	}
}

.button--arrow {
	display: inline-block;
	position: relative;
	font-size: 16px;
	font-weight: 400;
	font-style: italic;
	text-decoration: none;
	white-space: nowrap;
	text-transform: uppercase;
	padding: 16px 15px 13px;
	margin: 10px 22px;
	border: 3px solid $white;
	border-left: 0;
	border-right: 0;
	color: white;
	line-height: 23px;
	@include transition(transform);
	&:hover,&:focus {
		transform: scale(1.05);
		box-shadow: 5px 5px 25px rgba(0,0,0,0.16)
	}
	&:before, &:after {
		content: "";
		background-image: url(../images/arrows.svg);
		background-size: 42px 348px;
		position: absolute;
		top: -3px;
		height: 58px;
		width: 21px;
	}
	&:before {

		left: -19px;
		background-position: left -58px
	}
	&:after {
		right: -19px;
		background-position: right -58px
	}
	&:hover,&:focus {
		background: $white;
		color: $primary-color;
		&:before {
			background-position: left -116px
		}
		&:after {
			background-position: right -116px
		}
	}
	&.button--pink {
		//background: red;
		border-top-color: $primary-color;
		border-bottom-color: $primary-color;
		background: $white;
		color: $primary-color;
		&:hover {
			background: $primary-color;
			color: $white;
		}
		&:before {
			background-position: left 58px;
		}
		&:after {
			background-position: right 58px;
		}
		&:hover {
			&:before {
				background-position: left 0;
			}
			&:after {
				background-position: right 0;
			}
		}
	}
}

a.social {
	background: url(../images/pentagon-primary.svg);
	background-repeat: no-repeat;
	color: transparent;
	overflow: hidden;
	position: relative;
	width: 42px;
	height: 38px;
	line-height: 38px;
	display: inline-block;
	@include transition(all);
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 42px;
		height: 38px;
		text-align: center;
		font-size: 20px;
		font-family: FontAwesome;
		color: $primary-color;
	}
	&:hover {
		-webkit-transform: scale(1.2);
		-ms-transform: scale(1.2);
		transform: scale(1.2);
	}
	&.facebook::before {
		content: '\f09a'
	}

	&.twitter::before {
		content: '\f099'
	}

	&.linkedin::before {
		content: '\f0e1'
	}

	&.pinterest::before {
		content: '\f0d2'
	}

	&.instagram::before {
		content: '\f16d'
	}
}
.text-white {
	a.social {
		background: url(../images/pentagon-white.svg);
		&:before {
			color: $white;
		}
	}
}
