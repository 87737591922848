.post-list {
	margin-left: -1px;
	margin-right: -1px;
	.cell {
		padding: 2px;
	}
	a {
		position: relative;
		height: 275px;
		display: block;
		overflow: hidden;

		&:before {
			position: absolute;
			@include transition(all);
			left: 0;
			width: 0;
			height: 100%;
			content: '';
			background: $primary-color;
			display: block;
			top: 0;
			z-index: 6
		}

		&:hover {
			&:before {
				width: 5px;
			}
		}
	}
	@include breakpoint(large) {
		.big {
			a {
				height: 360px;
			}
		}
	}
	.image {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		background-position: center center;
		background-size: cover;
		@include transition(all);
	}

	.item-content-container {
		@include transition(all);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 5;
		display: block;
		top: 190px
	}
	@include breakpoint(large) {
		.big {
			.item-content-container {
				top: 275px;
			}
		}
	}
	.item-title {
		background: rgba(0, 0, 0, 0.5);
		font-size: 16px;
		font-weight: 600;
		color: white;
		text-transform: uppercase;
		padding: 12px 15px;
		display: block;
		height: 85px;
		h2 {
			font-size: inherit;
			font-weight: inherit;
			margin-bottom: 4px;
			min-height: 32px;
		}
		.item-date {
			font-size: 12px;
			font-weight: 500;
		}
	}

	.item-content {
		background: $primary-color;
		font-size: 15px;
		font-weight: 300;
		color: white;
		padding: 8px 70px 5px 15px;
		display: block;
		height: 85px;
		position: relative;

		p {

			font-size: 15px;
			font-weight: 300;
			margin-top: 0;
			margin-bottom: 0;
		}
		&::before{
			content: url("../images/ico-arrow.svg");
			position: absolute;
			top: -33px;
			margin-left: 50%;
			-ms-transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			transform: rotate(90deg);
			left: -7px;
			width: 14px;
			height: 33px;
		}
		&::after{
			background: url("../images/pentagon-item-list.svg") no-repeat;
			position: absolute;
			content:'\f061';
			font-family: FontAwesome;
			color: #ba2e41;
			top: 50%;
			right: 10px;
			z-index: 50;
			width: 51px;
			height: 44px;
			text-align: center;
			line-height: 43px;
			margin-top: -22px;
		}
	}


	a:hover {
		.image {
			//width: 80%;
			right: -5px;
			height: 90%;
		}
		.item-content-container {
			top: 105px;
			.item-content{
				//top: 165px;
				&::before{
					-ms-transform: rotate(-90deg);
					-webkit-transform: rotate(-90deg);
					transform: rotate(-90deg);
				}
			}
		}
	}
	@include breakpoint(large) {
		.big {
			a:hover {
				.item-content-container {
					top: 190px;
				}
			}
		}
	}
}
