.page-header {
	padding: 30px 0;
	@include breakpoint(medium) {
		//padding: 50px 0;
	}
	h1 {
		line-height: 1;
		font-size: 2.1875rem;
		@include breakpoint(large){
			font-size: 3.125rem;
		}
	}
	hr {
		margin: 2.5rem 0 1.5rem;
	}
	a {
		&.back {
			font-size: .875rem;
			font-weight: 700;
			color: white;
			text-transform: uppercase;
			position: relative;
			padding-left: 30px;
			margin-top: -20px;
		}

		&.category {
			font-weight: 700;
		}

		&.back::before {
			transition: all 150ms ease-in-out;
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: -.0625rem;
			background-image: url(../images/white-arrow--left.svg);
			background-repeat: no-repeat;
			width: 20px;
			height: 20px;
		}
	}
}